import * as React from "react"
import Layout from "../layouts/es"

// markup
const NotFoundPage = () => {
  return (
    <Layout pageId="notFound">
      <h1>404</h1>
      <h2>Contenido No encontrado</h2>
    </Layout>
  )
}

export default NotFoundPage
